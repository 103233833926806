import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

import { getPath } from "@/clients/contentful";
import { Link } from "@/common/components/link";

export function ListItem({ node }: { node: any }) {
  const UntaggedChildren = documentToReactComponents(node, {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} className="underline" target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link className="underline" href={getPath(node.data.target)}>
          {children}
        </Link>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node, children) => children,
    },
  });
  return <li>{UntaggedChildren}</li>;
}
