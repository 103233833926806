"use client";

import { useTranslations } from "next-intl";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "@/common/components/button";
import { Input } from "@/common/components/form/input";
import { createAndSetCSRFToken } from "@/features/email-subscription/actions/csrf-token-utils";
import { subscribeViaEmail } from "@/features/email-subscription/actions/subscribe";

export type SubscriptionFormInput = {
  csrfToken: string;
  name?: string;
  email: string;
};

export function SubscriptionForm() {
  const t = useTranslations("common");

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitSuccessful, isSubmitted, errors },
  } = useForm<SubscriptionFormInput>({ mode: "onBlur" });

  useEffect(() => {
    const getCsrfToken = async () => {
      const csrfToken = await createAndSetCSRFToken();

      setValue("csrfToken", csrfToken);
    };
    getCsrfToken();
  }, []);

  const submitHandler = async (args: SubscriptionFormInput) => {
    const res = await subscribeViaEmail(args);
    if (!res) {
      setError("email", { message: "error-occurred-try-again" });
    }
  };

  return (
    <div className="w-full flex-1">
      {isSubmitted && Object.keys(errors).length > 0 && (
        <div className="font-medium">
          <p className="text-center text-xl text-black">{t("error-occurred")}</p>
          <p className="text-center">{t("error-newsletter-subscription")}</p>
        </div>
      )}
      {isSubmitted && isSubmitSuccessful && (
        <div className="font-medium">
          <p className="text-center text-xl text-black">{t("newsletter-subscription-thanks")}</p>
          <p className="text-center">{t("newsletter-subscription")}</p>
        </div>
      )}
      {!isSubmitted && (
        <form onSubmit={handleSubmit(submitHandler)} className="flex flex-row items-center gap-5">
          {/* honeypot input - trap for bots */}
          <input
            id="subscribe-name"
            type="text"
            name="name"
            value=""
            autoComplete="off"
            tabIndex={-1}
            style={{ position: "absolute", left: "-7890px" }}
          />
          <input type="hidden" {...register("csrfToken")} />
          <Input
            {...register("email")}
            type="email"
            className="w-full"
            inputClassName="!border-grey-80"
            variant="solid"
            name="email"
            autoComplete="email"
            placeholder="Email"
          />
          <Button className="uppercase" type="submit">
            {t("button-subscribe")}
          </Button>
        </form>
      )}
    </div>
  );
}
