import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

import { getPath } from "@/clients/contentful";
import { Link } from "@/common/components/link";
import { OrderedList } from "@/common/components/ordered-list";
import { Text } from "@/common/components/text";
import { UnorderList } from "@/common/components/unorder-list";
import { ListItem } from "@/features/store/components/list-item";

export const defaultDocumentToReactComponentsOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <Text variant="pageHeading">{children}</Text>,
    [BLOCKS.HEADING_2]: (node, children) => <Text variant="subHeading">{children}</Text>,
    [BLOCKS.HEADING_3]: (node, children) => <Text variant="mediumHeading">{children}</Text>,
    [BLOCKS.HEADING_4]: (node, children) => <Text variant="heading">{children}</Text>,
    [BLOCKS.UL_LIST]: (node, children) => <UnorderList>{children}</UnorderList>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (node) => <ListItem node={node} />,
    [BLOCKS.TABLE]: (node, children) => (
      <div className="-mx-4 mb-5 overflow-x-auto text-left lg:max-w-[880px]">
        <table className="mx-4 table overflow-hidden rounded-t-xl">{children}</table>
      </div>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => (
      <tr className="first:!bg-grey-20 odd:bg-grey-10 *:px-4 *:py-6">{children}</tr>
    ),
    // Inlines
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <Link className="underline" href={getPath(node.data.target)}>
        {children}
      </Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} className="underline" target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  },
};
