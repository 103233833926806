export const scrollToElement = function (element_id: string, blockAlign = "start") {
  const elements = document.querySelectorAll(`[id=${element_id}]`);
  const element = Array.from(elements).find((el) => (el as never as HTMLElement).offsetParent);

  element?.scrollIntoView({
    behavior: "smooth",
    block: blockAlign as ScrollLogicalPosition,
    inline: "start",
  });
};
