"use client";

import cn from "clsx";
import { CSSProperties } from "react";

import { scrollToElement } from "@/common/utils/scroll-to-element";

type ScrollToProps = {
  label: string;
  elementID: string;
  style?: CSSProperties;
  className?: string;
};

export function ScrollTo({ label, elementID, style, className = "" }: ScrollToProps) {
  return (
    <div
      className={cn("cursor-pointer scroll-smooth font-normal italic underline", className)}
      onClick={() => {
        scrollToElement(elementID);
      }}
      style={style}
    >
      {label}
    </div>
  );
}
